<!-- 用户管理 -->

<template>
	<div class="members main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:isSerialNum="true" :apiName="SystemManage.getUserLists" :filters="filters" :columns="tableColumns"
				@onResetPasswBtn="onResetPasswBtn" @onPurviewBtn="onPurviewBtn" @onGroupingBtn="onGroupingBtn"
				@statusChange="statusChange">
				<!-- v-if="authData.indexOf('h_4SShttErG9guavsLxXh1pNBZrCAL') != -1" -->
				<template #operate>
					<el-button type="primary" round @click="onAddUsersBtn">
						<el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
						新增用户
					</el-button>
				</template>
			</common-table>
		</div>

		<!-- 新增用户 -->
		<w-dialog ref="addDialog" class="add-dialog" title="新增用户" width="38%" btnWidth="140px" top="20vh"
			confirmText="确认新增" @wConfirm="addConfirmUsers">
			<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="姓名" prop="u_name">
							<el-input v-model="ruleForm.u_name" placeholder="请输入姓名"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="账号" prop="u_tel">
							<el-input v-model="ruleForm.u_tel" placeholder="请输入账号"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="性别" prop="u_sex">
							<el-select v-model="ruleForm.u_sex" clearable placeholder="请选择性别">
								<el-option v-for="(item, index) in sexOption" :key="index" :label="item.value"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="身份证" prop="u_idcard">
							<el-input v-model="ruleForm.u_idcard" placeholder="请输入身份证号"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="用户类型" prop="u_type">
							<el-select v-model="ruleForm.u_type" clearable placeholder="请选择类型">
								<el-option v-for="(item, index) in typeOption" :key="index" :label="item.value"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>

		<!-- 分配权限范围 -->
		<w-dialog ref="authDialog" class="auth-dialog" title="分配权限范围" width="60%" btnWidth="140px" top="10vh"
			confirmText="确认保存" @wConfirm="authConfirm">
			<div class="mcontent">
				<div class="flex">
					<div class="name s-m-r">所属应用</div>
					<div class="select">
						<el-select v-model="m_id" placeholder="请选择权限模块" @change="moduleChange">
							<el-option :label="item.name" :value="item.id" v-for="item in authModules" :key="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="checkbox-wp flex" v-if="treeData[0]">
					<div class="left-wp">
						<div class="tree-wp" v-loading="treeLoading" element-loading-text="数据加载中">
							<el-scrollbar style="height: 100%">
								<el-tree ref="tree" :data="treeData" :props="{children: 'child',label: 'name',}"
									accordion node-key="id" :default-expanded-keys="defaultExpanded"
									@node-click="nodeClick" />
							</el-scrollbar>
						</div>
					</div>
					<div class="right-wp">
						<div class="content">
							<el-table ref="tableref"   :data="authTree" stripe style="width: 100%"
								element-loading-text="数据加载中" tooltip-effect="light"
								 @selection-change="handleSelectionChange"
								 @select="handleSelectChange"
								height='500' :row-key="getRowKeys">
								<el-table-column type="selection" width="55" :reserve-selection="true" />
								<el-table-column prop="name" label="电站名称" show-overflow-tooltip />
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</w-dialog>

		<!-- 权限分组 -->
		<PermissionGrouping ref="refGrouping" @addConfirm="addConfirmGrouping"></PermissionGrouping>
	</div>
</template>
<script setup>
	import {
		ref,
		reactive,
		onMounted,
		computed,
		watch
	} from "vue";
	import {
		SystemManage,
	} from "@/plugins/api.js";
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import PermissionGrouping from "../components/permissionGrouping.vue";
	import imgUpload from "@/components/img-upload/img-upload.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true,
		}
	);
	/** 表格对象 */
	const roleTable = ref(null);
	const sexOption = ref([]);
	const typeOption = ref([]);
	// 模块列表
	const authModules = ref([]);
	/** 筛选条件列表 */
	const filters = ref([{
			filterType: "select",
			name: "type",
			value: "",
			placeholder: "请选择人员类型",
			options: [],
			lab: "value",
			val: "id",
		},
		{
			filterType: "select",
			name: "status",
			value: "",
			placeholder: "请选择人员状态",
			options: [],
			lab: "value",
			val: "id",
		},
		{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入用户姓名或手机号",
		},
	]);

	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "u_name",
			label: "姓名",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "user_type_text",
			label: "类型",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "u_tel",
			label: "账号",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "user_sex_text",
			label: "性别",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "u_idcard",
			label: "身份证号",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "u_status",
			type: "switch",
			label: "状态",
			token: "members",
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 270,
			bottons: [{
					name: "权限范围",
					action: "onPurviewBtn",
					token: "members",
				},
				{
					name: "权限分组",
					action: "onGroupingBtn",
					token: "members",
				},
				{
					name: "重置密码",
					action: "onResetPasswBtn",
					token: "members",
				},
			],
		},
	]);
	// 新增用户弹框对象
	const addDialog = ref(null);
	// 详情组件对象
	const refGrouping = ref(null);
	// 编辑对话框对象
	const editDialog = ref(null);
	// 上传数据
	const fileList = ref([]);
	/** 表单对象 */
	const addForm = ref(null);
	/** 表单数据对象 */
	const ruleForm = ref({
		u_name: '', // 姓名
		u_tel: '', // 账号
		u_sex: '', // 性别
		u_idcard: '', // 身份证
		u_type: '', // 类型
	});
	const m_id = ref(''); // 所属应用
	const authTree = ref([]); // 权限范围
	const treeData = ref([]); // 树形数据
	const treeLoading = ref(false); // 树结构加载标识
	const defaultExpanded = ref([]); // 默认展开节点
	const multipleSelection = ref([]); // 权限范围
	const currentRangeRow = ref(''); // 分配权限范围当前行数据
	const authDialog = ref(null); // 权限弹框
	const tableref = ref(null); // 表格对象
	/** 表单规则对象 */
	const rules = reactive({
		u_name: [{
			required: true,
			message: "请输入姓名",
			trigger: "blur",
		}, ],
		u_tel: [{
			required: true,
			message: "请输入账号",
			trigger: "blur",
		}, ],
		u_sex: [{
			required: true,
			message: "请选择性别",
			trigger: "blur",
		}],
		u_type: [{
			required: true,
			message: "请选择类型",
			trigger: "change",
		}, ],
	});
	/**
	 * 
	 * 性别
	 * 
	 * */
	const getUserSex = () => {
		SystemManage.getUserSex().then((res) => {
			if (res.Code === 200) {
				sexOption.value = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 状态
	 * 
	 * */
	const getUserStatus = () => {
		SystemManage.getUserStatus().then((res) => {
			if (res.Code === 200) {
				filters.value[1].options = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 类型
	 * 
	 * */
	const getUserType = () => {
		SystemManage.getUserType().then((res) => {
			if (res.Code === 200) {
				typeOption.value = res.Data ? res.Data : [];
				filters.value[0].options = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 模块列表
	 * 
	 * */
	const getModuleLists = () => {
		SystemManage.getModuleLists().then((res) => {
			if (res.Code === 200) {
				authModules.value = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 新增用户按钮
	 * 
	 * */
	const onAddUsersBtn = () => {
		ruleForm.value = {
			u_name: '', // 姓名
			u_tel: '', // 账号
			u_sex: '', // 性别
			u_idcard: '', // 身份证
			u_type: '', // 类型
		};
		addDialog.value.show();
	}
	/**
	 * 
	 * 新增用户-确认新增按钮
	 * 
	 * */
	const addConfirmUsers = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				let params = JSON.parse(JSON.stringify(ruleForm.value));
				addDialog.value.isLoading = true; // 按钮加载中状态
				SystemManage.addUsers(params).then((res) => {
					addDialog.value.isLoading = false;
					if (res.Code === 200) {
						roleTable.value.tableLoad(); // 重新获取列表数据
						ElMessage.success("新增成功"); // 成功提示
						addDialog.value.close(); // 关闭弹框
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
		});
	}
	/**
	 * 
	 * 修改当前状态
	 * 
	 * */
	const statusChange = (row) => {
		let data = {
			u_id: row.u_id,
			u_status: row.u_status == 1 ? 2 : 1,
		};
		SystemManage.modifyUsersStatus(data).then((res) => {
			if (res.Code === 200) {
				ElMessage.success("状态修改成功！");
				roleTable.value.tableLoad(); // 重新获取列表数据
			} else {
				ElMessage.error(res.Message);
			}
		});
	};
	/**
	 * 
	 * 权限范围按钮
	 * 
	 * */
	const onPurviewBtn = (row) => {
		defaultExpanded.value = [];
		treeData.value = [];
		authTree.value = [];
		m_id.value = '';
		currentRangeRow.value = row;
		getModuleLists();
		authDialog.value.show();
	}

	/**
	 * 
	 * 选择所属应用
	 * 
	 * */
	const moduleChange = () => {
		SystemManage.getModuleRangeLists({
			u_id: currentRangeRow.value.u_id,
			m_id: m_id.value
		}).then((res) => {
			if (res.Code === 200) {
				multipleSelection.value = [];
				treeData.value = res.Data ? res.Data : [];
				if(tableref.value) {
					tableref.value.clearSelection();
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	};
	/**
	 * 
	 * 表格选项
	 * 
	 * */
	const getRowKeys = (row) => {
		return row.id;
	}
	/**
	 * 
	 * 节点点击回调
	 * 
	 * */
	const nodeClick = (node) => {
		if (node.station) {
			authTree.value = node.station.length > 0 ? node.station : [];
			// 回显已关联
			if (authTree.value.length > 0) {
				multipleSelection.value = []
				authTree.value.forEach(item => {
					multipleSelection.value.push(item);
					if (item.checked) {
						tableref.value.toggleRowSelection(item, true);
					}
				})
			}
		} else {
			authTree.value = [];
		}
	};
	/**
	 * 
	 * 选择权限分组
	 * 
	 * */
	
	const handleSelectionChange= (arr) =>{
		// multipleSelection.value.forEach(res =>{
		// 	if(arr.length == 0){
		// 		res.checked = false
		// 	}else{
		// 		arr.forEach(resp =>{
		// 			if(res.id == resp.id){
		// 				res.checked = true
		// 			}else{
		// 				res.checked = false
		// 			}
		// 		})
		// 	}
		// })
	}

	const handleSelectChange = (arr,val) => {
		// val.checked = !val.checked
		val.checked = !val.checked;
	}

	const reduceArr = (arr) => {
		let newArr = [];
		let obj = {};
		for (var i = 0; i < arr.length; i++) {
			if (!obj[arr[i].id]) {
				newArr.push(arr[i])
				obj[arr[i].id] = true
			}
		}
		return newArr
	}
	
	
	/**
	 * 
	 * 分配权限范围确认按钮
	 * 
	 * */
	const authId = ref([])
	const getAuthId = (data) => {
		data.forEach(resp => {
			if (resp.child) {
				getAuthId(resp.child)
			}
			if (resp.station) {
				getAuthId(resp.station)
			}
			if (resp.checked) {
				authId.value.push(resp.id)
			}
		})
	}
	const authConfirm = () => {
		authId.value = []
		getAuthId(treeData.value)
		if (authId.value.length == 0) {
			ElMessage.error("请选择电站！");
			return;
		}
		let params = {
			u_id: currentRangeRow.value.u_id,
			m_id: m_id.value,
			module_range: authId.value.join(","),
		};
		authDialog.value.isLoading = true;
		SystemManage.allocationModuleRange(params).then((res) => {
			authDialog.value.isLoading = false;
			if (res.Code === 200) {
				ElMessage.success("操作成功！");
				authDialog.value.close();
				roleTable.value.tableLoad();
			} else {
				ElMessage.error(res.Message);
			}
		});
	};
	/**
	 * 
	 * 权限分组按钮
	 * 
	 * */
	const onGroupingBtn = (row) => {
		refGrouping.value.openDialog(row);
	}
	/**
	 * 
	 * 权限分组确认按钮
	 * 
	 * */
	const addConfirmGrouping = (data, u_id) => {
		refGrouping.value.dialogRef.isLoading = true;
		let arr = [];
		data.forEach((item) => {
			arr = arr.concat(item.id);
		});
		let params = {
			u_id: u_id,
			u_group: arr.join(","),
		};
		SystemManage.allocationGroup(params).then((res) => {
			refGrouping.value.dialogRef.isLoading = false;
			if (res.Code === 200) {
				ElMessage.success("操作成功！");
				refGrouping.value.dialogRef.close();
				roleTable.value.tableLoad();
			} else {
				let msg = res.Message ? res.Message : "权限修改失败！";
				ElMessage.error(msg);
			}
		});
	}
	/**
	 * 
	 * 重置密码按钮
	 * 
	 * */
	const onResetPasswBtn = (row) => {
		ElMessageBox.confirm(
				`是否确认重置密码？重置密码后密码为初始密码`,
				'重置密码', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
				}
			)
			.then(() => {
				SystemManage.resetPasswd({
					u_id: row.u_id
				}).then((res) => {
					if (res.Code === 200) {
						roleTable.value.tableLoad(); // 重新获取列表数据
						// ElMessage.success(`重置成功，密码为${res.Data.passwd}`);  // 成功提示
						ElMessageBox.confirm(
							`重置成功，密码为${res.Data.passwd}`,
							'温馨提示', {
								confirmButtonText: '确认',
								cancelButtonText: '取消',
							}
						).then(() => {

						})
					} else {
						ElMessage.error(res.Message);
					}
				});
			})
			.catch(() => {

			})
	};

	onMounted(() => {
		getUserSex();
		getUserStatus();
		getUserType();
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.left-wp {
		flex: 1;
		border-radius: 10px;
		overflow: hidden;
		background-color: #fff;
		padding: 20px 0;
		box-sizing: border-box;

		.tree-wp {
			.el-tree {
				padding: 0 15px;

				.el-tree-node {
					.el-tree-node__content {
						height: 40px;

						>label.el-checkbox {
							display: none;
						}

						.el-tree-node__expand-icon {
							font-size: 16px;
						}
					}

					.el-tree-node__content:hover {
						background-color: var(--search-bg-color);
					}
				}

				.el-tree-node:focus {
					>.el-tree-node__content {
						background-color: var(--search-bg-color);
					}
				}

				.el-tree-node.is-checked {
					>.el-tree-node__content {
						background-color: var(--search-bg-color);

						>.el-tree-node__label {
							color: var(--theme-color);
						}

						>.el-tree-node__expand-icon {
							color: var(--theme-color);
						}

						>.el-tree-node__expand-icon.is-leaf {
							color: transparent;
						}
					}
				}
			}
		}
	}

	.right-wp {
		flex: 3;
		border-radius: 10px;
		background-color: #fff;

		.content {
			padding: 20px;
		}
	}

	.members {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}
		}

		.auth-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}

			.mcontent {
				min-height: 600px;

				.name {
					font-size: 15px;
					color: var(--text-second-color);
					line-height: 40px;
				}
			}
		}
	}
</style>