<!-- 权限分组 -->

<template>
    <div class="power-detail-dialog">
        <w-dialog ref="dialogRef" title="权限分组" width="60%" top="6vh" @wConfirm="addConfirm">
            <el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
                tooltip-effect="light" @selection-change="handleSelectionChange" height='450' :row-key="getRowKeys">
                <el-table-column type="selection" width="55" :reserve-selection="true" />
                <el-table-column prop="name" label="电站名称" show-overflow-tooltip />
                <el-table-column prop="module_name" label="关联应用" show-overflow-tooltip />
                <el-table-column prop="node_name" label="权限信息" show-overflow-tooltip />
            </el-table>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, nextTick } from "vue";
    import { SystemManage, } from "@/plugins/api.js";
    import { ElMessage, } from "element-plus";

    export default {
        emits: ["addConfirm"],
        components: {},
        setup(props, { emit }) {
            // 弹框对象
            const dialogRef = ref(null);
            const tableref = ref(null);
            const tableDatas = ref([]);  // 表格数据
            const multipleSelection = ref([]);  // 选中分组
            const u_id = ref(null);  // 人员id
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = (row) => {
                multipleSelection.value = [];
                u_id.value = row.u_id;
                getGroupLists();
                dialogRef.value.show();
            };
            /**
             * 
             * 获取分组列表数据
             * 
             * */
            const getGroupLists = () => {
                SystemManage.getGroupLists({ u_id: u_id.value }).then((res) => {
                    if (res.Code === 200) {
                        tableDatas.value = res.Data.list ? res.Data.list : [];
                        // 回显已关联
                        if (tableDatas.value.length > 0) {
                            tableDatas.value.forEach(item => {
                                if (item.checked) {
                                    multipleSelection.value.push(item);
                                    tableref.value.toggleRowSelection(item, true);
                                }
                            })
                        }
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            /**
             * 
             * 选择权限分组
             * 
             * */
            const handleSelectionChange = (val) => {
                multipleSelection.value = val;
            }
            /**
             * 
             * 确认新增按钮
             * 
             * */
            const addConfirm = () => {
                emit("addConfirm", multipleSelection.value, u_id.value);
            }
            const getRowKeys = (row) => {
                return row.id;
            }

            return {
                openDialog,
                dialogRef,
                addConfirm,
                tableDatas,
                multipleSelection,
                handleSelectionChange,
                u_id,
                getRowKeys,
                tableref,
            };
        },
    };
</script>

<style lang="scss">

</style>